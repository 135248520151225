import AppRowContainer from '../../../shared/Components/AppRowContainer';
import AppCard from '../../../shared/Components/AppCard';
import styled from 'styled-components';

export const StyledPackageOneCard = styled(AppCard)`
  align-items: center;
`;
export const StyledPackageOneRow = styled(AppRowContainer)`
  max-width: 1000px;
  justify-content: center;
`;

import React, { useState, useContext } from "react";
import { Row, Col, Steps, Card } from "antd";
import LeftPane from "./Wizard/LeftPane";
import StepOne from "./Wizard/RightPane/Step1";
import StepTwo from "./Wizard/RightPane/Step2";
import StepThree from "./Wizard/RightPane/Step3";
import { FormOutlined } from "@ant-design/icons";

const { Step } = Steps;

const CheckTemplate = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCheck, setSelectedCheck] = useState("check1a.png");
  const [checkOptions, setCheckOptions] = useState({
    checkPosition: "top",
    checkType: "preprinted",
  });

  return (
    <>
      {/* <h1>
        <FormOutlined /> New Check Template
      </h1> */}
      <Row gutter={16}>
        
      </Row>

        <Row gutter={16}>
          <Col span={12}>
            <LeftPane
              selectedCheck={selectedCheck}
              checkOptions={checkOptions}
            />
          </Col>
          <Col span={12}>
            <Steps current={currentStep} onChange={setCurrentStep}>
              <Step title="Step 1" />
              <Step title="Step 2" />
              <Step title="Step 3" />
            </Steps>
            <div>
              {currentStep === 0 && (
                <Row style={{marginTop:"50px"}}>
                <StepOne 
                  setSelectedCheck={setSelectedCheck}
                  checkOptions={checkOptions}
                  setCheckOptions={setCheckOptions}
                  setCurrentStep={setCurrentStep}
                />
                </Row>
              )}
              {currentStep === 1 && <StepTwo setCurrentStep={setCurrentStep} />}
              {currentStep === 2 && <StepThree setCurrentStep={setCurrentStep} />}
            </div>
          </Col>
        </Row>

    </>
  );
};

export default CheckTemplate;

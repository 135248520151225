export const defaultTemplate = {
  title: "Quickbooks Compatible Template",
  bankNickname: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  enabled: true,
  isDeleted: false,
  bankAccount: {
    id: null
  },
  imgSrc: null,
  options: {
    printSignature: false,
    printCheckNumber: false,
    printPayer: false,
    printLines: false,
    printBackground: false,
    backgroundUrl: null,
    printRoutingAndAccount: false,
  },
  coordinates: {
    bankNickname: [
      { x: 25, y: 465 },
      { x: 25, y: 717 },
    ],
    mailingInfo: {
      x: 58,
      y: 130,
    },
    writtenDollar: {
      y: 107,
      x: 22,
    },
    date: [
      {
        x: 490,
        y: 46,
      },
      {
        x: 48,
        y: 262,
      },
      {
        x: 48,
        y: 512,
      },
    ],
    payableTo: [
      {
        x: 70,
        y: 82,
      },
      {
        x: 135,
        y: 262,
      },
      {
        x: 135,
        y: 512,
      },
    ],
    memo: [
      {
        x: 48,
        y: 190,
      },
      {
        x: 230,
        y: 465,
      },
      {
        x: 230,
        y: 717,
      },
    ],
    amount: [
      {
        x: 490,
        y: 85,
      },
      {
        x: 520,
        y: 465,
      },
      {
        x: 520,
        y: 717,
      },
    ],
  },
};

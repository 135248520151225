import React from "react";
// import { Link } from 'react-router-dom'
import { useAuth } from "../../contexts/AuthContext";
import { Card, Typography, Row, Col, Button } from "antd";
import { DashboardOutlined } from "@ant-design/icons";
import axios from "axios";

function Landing() {
  const { Title } = Typography;
  const { user } = useAuth();

  return (
    <>
      <h1>
        <DashboardOutlined /> Dashboard
      </h1>
      <Row gutter={16}>
        <Col>
          <Card title="Card title" type="inner" bordered={false}>
            {user?.isEmployee && <pre>{JSON.stringify(user, null, 2)}</pre>}
          </Card>
        </Col>
        <Col>
          <Card title="Card title" type="inner" bordered={false}>
            Card content
          </Card>
        </Col>
        <Col>
          <Card title="Card title" type="inner" bordered={false}>
            Card content
          </Card>
        </Col>
        <Col>{/* <Button onClick={sendEmail}>Send Test Email</Button> */}</Col>
      </Row>
    </>
  );
}

export default Landing;

import React from 'react';
import CheckImage from './CheckImage';
import UploadAndCrop from './UploadAndCrop';

const LeftPane = ({ selectedCheck, checkOptions }) => {
  return (
    <div>
      <CheckImage selectedCheck={selectedCheck} />
      {checkOptions.checkType === 'myown' && <UploadAndCrop />}
    </div>
  );
};

export default LeftPane;

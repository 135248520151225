import React from 'react';
import { Button } from 'antd';

const SystemPreferencesTab = () => {
  return (
    <div>
      <Button type="primary">Button 1</Button>
      <Button type="secondary">Button 2</Button>
    </div>
  );
};

export default SystemPreferencesTab;

import React from 'react';
import Draggable from 'react-draggable';

const DraggableCanvas = ({ coordinates, setCoordinates }) => {
  const handleDrag = (e, data, key) => {
    setCoordinates((prevItems) => ({
      ...prevItems,
      [key]: { ...prevItems[key], x: data.x, y: data.y },
    }));
  };

  const SCALE_FACTOR = 0.75; // 75% scale

  const X_SUBTRACT = 280;
  const Y_SUBTRACT = 175;

  const handleStop = (e, key) => {
    // console.log(e)
    console.log(
      `${key} record these coords x: ${
        (e.clientX - X_SUBTRACT) * SCALE_FACTOR
      } y: ${(e.clientY - Y_SUBTRACT) * SCALE_FACTOR}`
    );
  };

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '500px',

      }}
    >
      {Object.keys(coordinates).map((key) => (
        <Draggable
          key={key}
          position={{ x: coordinates[key].x, y: coordinates[key].y }}
          onDrag={(e, data) => handleDrag(e, data, key)}
          onStop={(e) => handleStop(e, key)}
        >
          <div
            style={{
              position: 'absolute',
           
            }}
          >
            {key}
          </div>
        </Draggable>
      ))}
    </div>
  );
};

export default DraggableCanvas;

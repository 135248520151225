import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { notification, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, collection, where, query, getDocs } from "firebase/firestore";
import { isTrialExpired } from "../utils/functions/checkTrialExpiration";
import LoadingSpinner from "../shared/Components/LoadingSpinner";

const AuthContext = createContext();
const IGNORED_ROUTES = ["/logout", "/terms-of-service", "/forgot-password"];

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true); 
  const [loading, setLoading] = useState(false); 
  const [invitation, setInvitation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (IGNORED_ROUTES.includes(currentPath)) {
      setInitialLoading(false);
      return;
    }
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      setInitialLoading(true); // Set initial loading to tru e only at the start
      // console.log("firebase user", firebaseUser.email);
      if (firebaseUser) {
        // Check if the token is older than 8 hours
        const tokenAge =
          Date.now() - new Date(firebaseUser.metadata.lastSignInTime).getTime();
        const maxTokenAge = 8 * 60 * 60 * 1000; // 8 hours in milliseconds

        if (tokenAge > maxTokenAge) {
          await signOut(auth);
          navigate("/login");
          notification.warning('Session expired, please login again')
          setInitialLoading(false);
          return;
        }

        try {
          const userRef = doc(db, "users", firebaseUser.uid);
          const userDoc = await getDoc(userRef);
          let enhancedUser = {
            uid: firebaseUser.uid,
            accessToken: firebaseUser.accessToken,
            email: firebaseUser.email,
            ...(userDoc.exists() ? userDoc.data() : {}),
          };

          if (enhancedUser && !enhancedUser.clientId) {
            // console.log('new user, checking if onboarding or invitation onboarding')
            //check if there's invitation for the user email
            const invitesCollection = collection(db, "invites");
            const q = query(invitesCollection, where("email", "==", firebaseUser.email));
    
            try {
              const inviteSnap = await getDocs(q);
    
              if (!inviteSnap.empty) {
                const data = inviteSnap.docs[0].data(); // Assuming you're interested in the first match
                setInvitation(data);
                console.log("is invitation", data); // Log the data as a JS object
              }
            } catch (error) {
              console.error("Error getting document:", error);
            }
            //if so then navigate to Invitation onboarding
            if(invitation){
              console.log('invitation found!, forwarding to invitation onboarding')
              setUser(firebaseUser);
              navigate("/invitation-onboarding");;
            }else{
              setUser(firebaseUser);
              navigate("/onboarding");
            }
          } else {
            const clientRef = doc(db, "clients", enhancedUser.clientId);
            const clientDoc = await getDoc(clientRef);

            if (clientDoc.exists()) {
              enhancedUser = {
                ...enhancedUser,
                clientData: clientDoc.data(),
              };

              if (enhancedUser?.clientData?.hasAccess) {
                // Check if trial is expired
                if (enhancedUser?.clientData?.subscription === "trial") {
                  const expired = await isTrialExpired(
                    enhancedUser.clientId,
                    enhancedUser.clientData.trialExpiresOn.toDate()
                  );
                  if (expired) {
                    navigate("/subscription");
                  }
                }
                setUser(enhancedUser);
              } else {
                navigate("/subscription");
              }
            } else {
              console.error("Client document not found");
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setUser(null);
        navigate("/logout");
      }
      setInitialLoading(false); // Set initial loading to false after all checks are done
    });

    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  if (initialLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <AuthContext.Provider
        value={{ user, logout, setUser, loading, setLoading, invitation }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import React from 'react';
import { Card, Row, Col } from 'antd';

const WordPressArticlesCard = ({ articles }) => {
    // console.log(articles)
    return (
        <Row gutter={[16, 16]}>
          {articles.map((article, index) => (
            <Col xs={24} sm={12} md={8} lg={6} key={index}>
              <Card
                hoverable
                cover={<img alt={article.title} src={article.image} />}
                onClick={() => window.open(article.url, '_blank')}
              >
                <Card.Meta
                  title={article.title}
                  description={<div dangerouslySetInnerHTML={{ __html: article.summary }} />}
                />
              </Card>
            </Col>
          ))}
        </Row>
      );
};

export default WordPressArticlesCard;
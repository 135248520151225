import React, { useContext, useEffect, useState } from "react";
import { Button, Table, message, Modal, Form, Input, Alert } from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import axios from "axios";
import moment from "moment";

const InviteUser = () => {
  const { user } = useAuth();
  const [invitations, setInvitations] = useState([]);
  const [invitationCount, setInvitationCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const api = process.env.REACT_APP_API_ENDPOINT;
  const [form] = Form.useForm();

  const fetchInvitations = async () => {
    try {
      const inviteResponse = await axios.get(`${api}/v1/users/invites`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      const validInvitations = inviteResponse.data.filter((invite) => {
        const isExpired = moment
          .unix(invite.dateExpire._seconds)
          .isBefore(moment());
        return !isExpired;
      });

      if (validInvitations.length < inviteResponse.data.length) {
        // Some invitations have expired, so call the delete expired invites endpoint
        await axios.delete(`${api}/v1/users/invites`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        });
      }

      setInvitations(validInvitations);
      setInvitationCount(validInvitations.length);
    } catch (error) {
      message.error("Failed to fetch invitations");
    }
  };

  const fetchUsers = async () => {
    try {
      const userResponse = await axios.get(`${api}/v1/users`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      setUserCount(userResponse.data.length);
    } catch (error) {
      message.error("Failed to fetch users");
    }
  };

  useEffect(() => {
    if (api && user) {
      fetchInvitations();
      fetchUsers();
    }
  }, [api, user]);

  const handleInvite = async (values) => {
    try {
      await axios.post(
        `${api}/v1/users/invite`,
        {
          ...values,
          clientId: user.clientId,
          clientName: user.companyName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      message.success("User invited successfully");
      form.resetFields();
      fetchInvitations();
      setIsModalVisible(false);
    } catch (error) {
      message.error("Failed to invite user");
    }
  };

  const handleDeleteInvite = async (inviteId) => {
    try {
      await axios.delete(`${api}/v1/users/invites/${inviteId}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      setInvitations(invitations.filter((invite) => invite.id !== inviteId));
      setInvitationCount(invitationCount - 1);
      message.success("Invite deleted successfully");
    } catch (error) {
      message.error("Failed to delete invite");
    }
  };

  const maxReached = userCount + invitationCount >= 5;
  const isPremium = user?.clientData?.subscription?.productName === "Premium";

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Expiration Date",
      dataIndex: "dateExpire",
      key: "dateExpire",
      render: (dateExpire) =>
        moment.unix(dateExpire._seconds).format("MM/DD/YYYY HH:mm:ss"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button danger onClick={() => handleDeleteInvite(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return user ? (
    <>
      <Button
        type="primary"
        onClick={showModal}
        style={{ marginBottom: 16 }}
        disabled={!isPremium || maxReached}
      >
        {!isPremium
          ? "Premium Feature"
          : maxReached
          ? "Max Maxed Out"
          : "Invite User"}
      </Button>
      <Modal
        title="Invite User"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout="vertical" onFinish={handleInvite}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input the email" }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item>
            <Alert
              type="warning"
              message="Please ask user to check the Spam/Junk folder for the invitation."
              showIcon
            />
            <Button type="primary" htmlType="submit" style={{ marginTop: 16 }}>
              Send Invite
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Table dataSource={invitations} columns={columns} rowKey="id" />
    </>
  ) : null;
};

export default InviteUser;

import { useParams, useNavigate } from "react-router-dom";
import { Button, Card , Typography} from "antd";
import { UserOutlined } from "@ant-design/icons";

function EditPayee() {
  const { payeeId } = useParams();
  const navigate = useNavigate();
  const { Text } = Typography;
  return (
    <>
      <h1>
        <UserOutlined /> Edit Payee
      </h1>
      <Card>
        {" "}
        <Button  onClick={() => navigate(-1)}>
          Close
        </Button>
        <br/>
        <br/>
        <br/>
        <Text>EditPayee {payeeId}</Text>
      </Card>
    </>
  );
}

export default EditPayee;

import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import Pricing from '../../Pricing'

const PaymentTab = () => {
  const { user } = useAuth();

  return (
   <Pricing />
  );
};

export default PaymentTab;

import React from "react";

const CheckImage = ({ selectedCheck }) => {
  const LETTER_WIDTH = 8.5 * 96; // 8.5 inches * 96 DPI
  const LETTER_HEIGHT = 11 * 96; // 11 inches * 96 DPI
  
  const SCALE_FACTOR = 0.75; // 50% scale
  
  const IMAGE_WIDTH = LETTER_WIDTH * SCALE_FACTOR;
  const IMAGE_HEIGHT = LETTER_HEIGHT * SCALE_FACTOR;

  return (
    <img
      width={IMAGE_WIDTH}
      height={IMAGE_HEIGHT}
      src={`images/checkTemplates/${selectedCheck}`} // adjust path as necessary
      alt="Check Preview"
      style={{opacity:"50%", border:"1px solid gray"}}
    />
  );
};

export default CheckImage;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { useThemeContext } from '../AppContextProvider/ThemeContextProvider';
import { getAntTheme } from '../../utils/ThemeHelper';

const AppThemeProvider = (props) => {
  const { theme } = useThemeContext();
    

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        // direction={direction}
        // locale={antLocale}
        theme={{
          token: getAntTheme(theme),
          fontFamily: 'Be Vietnam, sans-serif',
        }}
      >
        {props.children}
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default React.memo(AppThemeProvider);

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

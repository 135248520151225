import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { useAuth } from "../contexts/AuthContext";
import {
  SettingOutlined,
  QuestionCircleOutlined,
  EditOutlined,
  UserOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  FormOutlined,
} from "@ant-design/icons";

export default function MainMenu() {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate= useNavigate();
  const url = process.env.REACT_APP_SHOP_URL
  return (
    <Menu
      mode="inline"
      style={{
        backgroundColor: "#dfe6e9",
        height: "100vh",
        paddingTop: "15px",
      }}
      selectedKeys={[location.pathname]}
      defaultSelectedKeys={["/payees"]}
      items={[
        {
          key: "/payees",
          icon: <UserOutlined  />,
          label: "Payees",
          onClick: () => {
            navigate('/payees')
          },

        },
        {
          key: "/checks",
          icon: <EditOutlined />,
          label: "Checks",
          onClick: () => {
            navigate('/checks')
          }
        },
        {
          key: "/settings",
          icon: <SettingOutlined />,
          label: "Settings",
          onClick: () => {
            navigate('/settings')
          }
        },
        // {
        //   key: "/templates",
        //   icon: <FormOutlined />,
        //   label: "Templates",
        //   onClick: () => {
        //     navigate('/templates')
        //   }
        // },
        {
          key: "/shop",
          icon: <ShoppingCartOutlined />,
          label: "Buy Supplies" ,
          onClick: () => {
            window.location.href = process.env.REACT_APP_SHOP_URL          }
        },
        {
          key: "/help",
          icon: <QuestionCircleOutlined />,
          label: "Help",
          onClick: () => {
            navigate('/help')
          }
        },
        {
          key: "/logout",
          icon: <LogoutOutlined />,
          label: "Logout",
          onClick: () => {
            logout();
          },
        },
      ]}
    />
  );
}

import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import DraggableCanvas from '../Canvas';

const firestore = getFirestore();

const StepThree = () => {
  const defaultCoordinates = {
    writtenDollar: { y: 200, x: 100 },
    date:{ y: 200, x: 100},
    checkMemo: { y: 350, x: 100 },
    checkMemo2: { y: 300, x: 100},
    checkMemo3: { y: 250, x: 100 },
    payeeName: { y: 150, x: 100 },
    payeeCompany: { y: 250, x: 100 },
    dollarAmount: { x: 100, y: 400 },
    checkNumber: { x: 450, y: 50 },
    payeeAddress: { x: 100, y: 300 },
  };

  const [coordinates, setCoordinates] = useState(defaultCoordinates);

  useEffect(() => {
    const fetchCoordinates = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return;

      const docRef = doc(firestore, `clients/${user.clientId}/templates/defaultTemplate`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.coordinates) {
          setCoordinates(data.coordinates);
        }
      }
    };

    fetchCoordinates();
  }, []);

  return (
    <div>
      <DraggableCanvas coordinates={coordinates} setCoordinates={setCoordinates} />
    </div>
  );
};

export default StepThree;

import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useAuth } from '../../../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import {db} from '../../../firebase'; // Import the initialized Firestore instance

const UserDetailsTab = () => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    if (!values.displayName.includes(' ')) {
      return alert('Full name is required and must contain a space.');
    }

    setLoading(true);

    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, values);
      setUser({ ...user, ...values });
      notification.success({message: 'Profile updated successfully!'});
    } catch (error) {
      console.error('Error updating profile: ', error);
      notification.error({message:'Failed to update profile.'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      initialValues={user}
      onFinish={onFinish}
    >
      <Form.Item
        name="displayName"
        label="Full Name"
        rules={[{ required: true, message: 'Full name is required' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="companyName"
        label="Company Name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>Save</Button>
      </Form.Item>
    </Form>
  );
};

export default UserDetailsTab;

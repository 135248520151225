// src/components/ResetPassword.js
import React, { useState } from 'react';
import { Form, Input, Button, Typography, notification, Card } from 'antd';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';

const { Text } = Typography;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const navigate = useNavigate();
  const oobCode = query.get('oobCode');
  const [email, setEmail] = useState('');

  // Check the validity of the reset code
  React.useEffect(() => {
    const verifyCode = async () => {
      try {
        const email = await verifyPasswordResetCode(getAuth(), oobCode);
        setEmail(email);
      } catch (error) {
        notification.error({ message: 'Invalid or expired reset code' });
        navigate('/login');
      }
    };

    if (oobCode) {
      verifyCode();
    } else {
      notification.error({ message: 'No reset code provided' });
      navigate('/login');
    }
  }, [navigate, oobCode]);

  const onFinish = async (values) => {
    setLoading(true);
    if (values.password !== values.confirmPassword) {
      notification.error({ message: 'Passwords do not match' });
      setLoading(false);
      return;
    }

    try {
      await confirmPasswordReset(getAuth(), oobCode, values.password);
      notification.success({ message: 'Password reset successful' });
      navigate('/login');
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <Card title="Reset Password" type="inner" style={{ width: 400, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        {/* <div style={{ textAlign: 'center' }}>
          <Title level={2}>Reset Password</Title>
        </div> */}
        <Text>Please enter your new password below.</Text>
        <Form name="reset_password" onFinish={onFinish} style={{marginTop:"15px"}}>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your new Password!' }]}
          >
            <Input.Password placeholder="New Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            hasFeedback
            rules={[
              { required: true, message: 'Please confirm your Password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ResetPassword;

import React, { useState, useEffect } from "react";
import { Button, Modal, Card, notification, Form, Row, Col, Input } from "antd";
import {
  PlusOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { StyleSheet } from "@react-pdf/renderer";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import CheckForm from "./Components/CheckForm";
import CheckTable from "./Components/CheckTable";
import PrintChecksModal from "./Components/PrintChecksModal";
import moment from "moment";
import * as XLSX from "xlsx";

const ChecksPage = () => {
  const [checks, setChecks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredChecks, setFilteredChecks] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editingCheck, setEditingCheck] = useState(null);
  const [form] = Form.useForm();
  const [payeeSearchResults, setPayeeSearchResults] = useState([]);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [selectedChecksToPrint, setSelectedChecksToPrint] = useState([]);
  const [isPDFModalVisible, setIsPDFModalVisible] = useState(false);
  const [startingCheckNumber, setStartingCheckNumber] = useState("");
  const [template, setTemplate] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const { user } = useAuth();

  const fetchChecks = async () => {
    try {
      const checksCollection = query(
        collection(db, `clients/${user.clientId}/checks`),
        where("isDeleted", "==", false),
        orderBy("dateAdded", "desc")
      );
      const checksSnapshot = await getDocs(checksCollection);
      const checksList = checksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChecks(checksList);
      setFilteredChecks(checksList);
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error fetching checks",
      });
    }
  };

  const fetchTemplate = async () => {
    const TEMPLATE_STORAGE_KEY = "templateData";
    try {
      // Check if template is already stored in local storage
      const storedTemplate = localStorage.getItem(TEMPLATE_STORAGE_KEY);

      if (storedTemplate) {
        // If template is found in local storage, parse it and set it in state
        setTemplate(JSON.parse(storedTemplate));
      } else {
        // If not found in local storage, fetch it from Firebase
        const templatesCollection = collection(
          db,
          `clients/${user.clientId}/templates`
        );
        const templateSnapshot = await getDocs(templatesCollection);

        if (!templateSnapshot.empty) {
          const allTemplateData = templateSnapshot.docs.map((doc) =>
            doc.data()
          );

          // Store the fetched templates in local storage
          localStorage.setItem(
            TEMPLATE_STORAGE_KEY,
            JSON.stringify(allTemplateData)
          );

          // Set the fetched templates in state
          setTemplate(allTemplateData);
        }
      }
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to fetch templates",
      });
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = checks.filter((check) =>
      Object.keys(check).some((key) =>
        String(check[key]).toLowerCase().includes(value)
      )
    );
    setFilteredChecks(filteredData);
  };

  useEffect(() => {
    fetchChecks();
    fetchTemplate();
  }, []);

  const fetchLastPayer = async () => {
    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      return lastCheckSnapshot.docs[0].data().payer;
    }
    return null;
  };

  const exportToCsv = () => {
    const dataToExport = filteredChecks.map((check) => ({
      "Check Number": check.checkNumber,
      Amount: `$${parseFloat(check.amount).toFixed(2)}`,
      Status: check.status,
      Payee: check.payee.name,
      "Payable To": check.payableTo,
      Date: moment(check.date.toDate()).format("YYYY-MM-DD"),
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "checks.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchPayers = async () => {
    const payersCollection = collection(db, `clients/${user.clientId}/payers`);
    const payersSnapshot = await getDocs(payersCollection);
    return payersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const fetchTemplates = async () => {
    const templatesCollection = collection(
      db,
      `clients/${user.clientId}/templates`
    );
    const templatesSnapshot = await getDocs(templatesCollection);
    return templatesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const showModal = async (check = null) => {
    const payers = await fetchPayers();
    const templates = await fetchTemplates();
    const lastPayer = await fetchLastPayer();

    setEditingCheck(check);
    setIsEditMode(!!check);
    form.setFieldsValue(
      check
        ? {
            ...check,
            date: moment(check.date.toDate()),
            toBePrinted: false
          }
        : { payer: lastPayer ? lastPayer : "", payers, templates }
    );
    setIsModalVisible(true);
  };

  const handleOk = async (formattedValues) => {
    try {
      const now = new Date();

      if (isEditMode && editingCheck) {
        await updateDoc(
          doc(db, `clients/${user.clientId}/checks`, editingCheck.id),
          {
            ...formattedValues,
            dateModified: now,
          }
        );
      } else {
        // console.log(formattedValues);
        await addDoc(collection(db, `clients/${user.clientId}/checks`), {
          ...formattedValues,
          dateAdded: now,
          dateModified: now,
          isDeleted: false,
          clientId: user.clientId,
        });
      }
      // setIsModalVisible(false);
      fetchChecks();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to edit this record",
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = async (id) => {
    try {
      const now = new Date();
      await updateDoc(doc(db, `clients/${user.clientId}/checks`, id), {
        isDeleted: true,
        dateModified: now,
        lastEditedBy: { displayName: user.displayName, uid: user.uid },
      });
      fetchChecks();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "You don't have permission to edit this record",
      });
    }
  };

  const handlePayeeSearch = async (value) => {
    if (value.length < 3) return;

    const payeesQuery = query(
      collection(db, `clients/${user.clientId}/payees`),
      where("name", ">=", value),
      where("name", "<=", value + "\uf8ff")
    );
    const payeesSnapshot = await getDocs(payeesQuery);
    const payeesList = payeesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setPayeeSearchResults(payeesList);
  };

  const handlePayeeSelect = async (payeeId) => {
    const payeeDoc = await getDoc(
      doc(db, `clients/${user.clientId}/payees`, payeeId)
    );
    const payeeData = payeeDoc.data();
    form.setFieldsValue({
      payee: payeeId,
      payeeName: payeeData.name,
      payeeAddress: payeeData.address,
    });

    const lastCheckQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      where("payee.id", "==", payeeId),
      orderBy("date", "desc"),
      limit(1)
    );
    const lastCheckSnapshot = await getDocs(lastCheckQuery);
    if (!lastCheckSnapshot.empty) {
      const lastCheckData = lastCheckSnapshot.docs[0].data();
      form.setFieldsValue(lastCheckData);
    }
  };

  const openPrintModal = async () => {
    const checksToPrintQuery = query(
      collection(db, `clients/${user.clientId}/checks`),
      where("toBePrinted", "==", true)
    );
    const checksToPrintSnapshot = await getDocs(checksToPrintQuery);
    const checksList = checksToPrintSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSelectedChecksToPrint(checksList);
    setIsPrintModalVisible(true);
  };

  const handlePrint = () => {
    setIsPrintModalVisible(false);
    setIsPDFModalVisible(true);
  };

  const handlePrintConfirm = async (printedCorrectly) => {
    if (printedCorrectly) {
      for (let i = 0; i < selectedChecksToPrint.length; i++) {
        const check = selectedChecksToPrint[i];
        await updateDoc(doc(db, `clients/${user.clientId}/checks`, check.id), {
          checkNumber: parseInt(startingCheckNumber) + parseInt(i),
          status: "printed",
          printedDate: Date.now(),
          toBePrinted: false,
        });
      }
      notification.success({
        message: "Checks printed and updated successfully",
      });
      fetchChecks();
    }
    setIsPDFModalVisible(false);
  };

  return (
    <>
      <h1><EditOutlined /> Checks</h1>
      <Card>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col span={12}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                form.resetFields();
                setEditingCheck(false)
                showModal(null);
              }}
              style={{ marginRight: 8 }}
            >
              New Check
            </Button>
            <Button
              type="dashed"
              icon={<PrinterOutlined />}
              onClick={openPrintModal}
              style={{ marginRight: 8 }}
            >
              Print Checks
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input.Search
              placeholder="Search by any field"
              value={searchText}
              onChange={handleSearch}
              style={{ marginRight: 10, width: 300 }}
            />
            <Button
              type="dashed"
              icon={<FileExcelOutlined />}
              onClick={exportToCsv}
            >
              Export to CSV
            </Button>
          </Col>
        </Row>
        <CheckTable
          checks={filteredChecks}
          showModal={showModal}
          handleDelete={handleDelete}
        />
        <Modal
          title={isEditMode ? "Edit Check" : "New Check"}
          open={isModalVisible}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{ minWidth: "80%", marginTop: -35 }}
        >
          <CheckForm
            form={form}
            maskClosable={false}
            isVisible={isModalVisible}
            isEditMode={isEditMode}
            editingCheck={editingCheck}
            onSubmit={handleOk}
            onCancel={handleCancel}
            payers={form.getFieldValue("payers")}
            templates={template}
            payeeSearchResults={payeeSearchResults}
            handlePayeeSearch={handlePayeeSearch}
            handlePayeeSelect={handlePayeeSelect}
            user={user}
          />
        </Modal>
        <PrintChecksModal
          isVisible={isPrintModalVisible}
          maskClosable={false}
          onOk={handlePrint}
          onCancel={() => setIsPrintModalVisible(false)}
          checksToPrint={selectedChecksToPrint}
          selectedChecksToPrint={selectedChecksToPrint}
          setSelectedChecksToPrint={setSelectedChecksToPrint}
          startingCheckNumber={startingCheckNumber}
          setStartingCheckNumber={setStartingCheckNumber}
          template={template}
          isPDFModalVisible={isPDFModalVisible}
          setIsPDFModalVisible={setIsPDFModalVisible}
          handlePrintConfirm={handlePrintConfirm}
        />
      </Card>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export default ChecksPage;

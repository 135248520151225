import React, { useEffect, useState } from "react";
import { Tabs, Card } from "antd";
import UserDetailsTab from "./UserDetailsTab";
import PaymentTab from "./PaymentTab";
import SystemPreferencesTab from "./PreferencesTab";
import { SettingOutlined } from "@ant-design/icons";
import CheckTemplates from "../CheckTemplates";
import UserList from "./UserTab";
import IntegrationTab from "./IntegrationsTab";
import { useAuth } from "../../contexts/AuthContext";

const { TabPane } = Tabs;

const Settings = () => {
  const { user } = useAuth();
  const [isPremium, setIsPremium] = useState(false);
  const [isEnterprise, setIsEnterprise] = useState(false);

  useEffect(() => {
    if (user && user?.clientData?.subscription?.productName === "Premium")
      setIsPremium(true);
    if (user && user?.clientData?.subscription?.productName === "Enterprise") {
      setIsEnterprise(true);
      setIsPremium(true);

    }
  }, [user]);

  return (
    <>
      <h1>
        <SettingOutlined /> Settings
      </h1>
      <Card>
        <Tabs defaultActiveKey="1">
          <TabPane tab="User Details" key="1">
            <UserDetailsTab />
          </TabPane>
          <TabPane tab="Subscription" key="2">
            <PaymentTab />
          </TabPane>
          <TabPane
            tab={!isPremium ? "Users (Premium)" : "Users"}
            key="4"
            disabled={!isPremium}
          >
            <UserList />
          </TabPane>
          <TabPane
            tab={!isPremium ? "Bank Accounts (Premium)" : "Bank Accounts"}
            key="5"
            disabled={!isPremium}
          >
            <SystemPreferencesTab />
          </TabPane>
          <TabPane
            tab={!isPremium ? "Check Templates (Premium)" : "Check Templates"}
            key="6"
            disabled={!isPremium}
          >
            <CheckTemplates />
          </TabPane>
          <TabPane tab="System Preferences" key="3" disabled={!isPremium}>
            <SystemPreferencesTab />
          </TabPane>
          <TabPane
            tab={!isEnterprise ? "Integrations (Enterprise)" : "Integrations"}
            key="7"
            disabled={!isEnterprise}
          >
            <IntegrationTab />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default Settings;

import React, { useState } from "react";
import { Modal, Button } from "antd";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const UpdatePaymentInformation = ({ user }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const url = process.env.REACT_APP_API_ENDPOINT

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const paymentMethodResult = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
          email: user.email,
        },
      });

      if (paymentMethodResult.error) {
        setErrorMessage(paymentMethodResult.error.message);
        setIsProcessing(false);
        return;
      }

      const paymentMethodId = paymentMethodResult.paymentMethod.id;

      // Call your backend to update the payment method
      const response = await fetch(
        `${url}/v1/payments/stripe/update-payment-method`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.accessToken}`,
          },
          body: JSON.stringify({
            stripeCustomerId: user.clientData.stripeCustomerId,
            paymentMethodId,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setIsModalVisible(false);
        alert("Payment method updated successfully!");
      } else {
        setErrorMessage(data.error);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try again.");
    }

    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#32325d",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Update Payment Information
      </Button>
      <Modal
        title="Update Payment Information"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <form onSubmit={handleSubmit} style={{marginTop:"50px"}}>
          <CardElement options={cardElementOptions}/>
          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <Button block type="primary" disabled={isProcessing || !stripe || !elements} onClick={handleSubmit} style={{marginTop:"50px"}}>
            {isProcessing ? "Processing..." : "Update Payment Method"}
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default UpdatePaymentInformation;

import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Button,
  Row,
  Col,
  Typography,
  Divider,
  notification,
} from "antd";
import moment from "moment";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "../../../firebase";
import PayeeModal from "../../Payees/Components/PayeeModal";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;
const { Title } = Typography;

const TO_BE_PRINTED_STATUSES = ["draft", "to be printed"];
const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;

const CheckForm = ({
  form,
  editingCheck,
  onSubmit,
  onCancel,
  templates,
  isVisible,
  user,
}) => {
  const [payeeSearchResults, setPayeeSearchResults] = useState([]);
  const [payeeDetails, setPayeeDetails] = useState({});
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isPayeeModalVisible, setIsPayeeModalVisible] = useState(false);
  const [checkAmount, setCheckAmount] = useState(null);
  const [toBePrinted, setToBePrinted] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (editingCheck) {
      if (DEBUG_MODE) console.log("editing check mode");
      form.setFieldsValue({
        ...editingCheck,
        date: moment(editingCheck.date.toDate()),
        payeeName: editingCheck.payee.name || "",
        payeeId: editingCheck.payee.id || "",
        mailingInfo: editingCheck.mailingInfo || "",
        amount: editingCheck.amount,
        payableTo: editingCheck.payableTo || "",
      });
      setPayeeDetails(editingCheck.payee);
      setToBePrinted(false);
    } else {
      newDefault();
    }
  }, [editingCheck, form, templates]);

  const newDefault = () => {
    form.setFieldsValue({
      checkNumber: "To Be Printed",
      status: "To Be Printed",
      template: templates[0]?.title || "",
    });
    setPayeeDetails({});
    setPayeeSearchResults({});
    setCheckAmount(null);
    setToBePrinted(true);
  };

  if (!form.getFieldValue("checkNumber")) {
    newDefault();
  }

  const handlePayeeSearch = (value) => {
    if (value.length < 3) return;
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(async () => {
        const searchQuery = query(
          collection(db, `clients/${user.clientId}/payees`)
        );

        getDocs(searchQuery)
          .then((snapshot) => {
            const results = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            const filteredResults = results.filter(
              (item) =>
                (item.name?.toLowerCase() ?? "").includes(
                  value.toLowerCase()
                ) ||
                (item.companyName?.toLowerCase() ?? "").includes(
                  value.toLowerCase()
                )
            );
            setPayeeSearchResults(filteredResults);
            setIsDropdownVisible(true);
          })
          .catch((error) => {
            console.error("Error fetching payees:", error);
          });
      }, 300)
    );
  };

  const handlePayeeSelect = async (payeeId) => {
    setPayeeDetails(null);
    if (payeeId === "add_new") {
      handleAddNewPayee();
    } else {
      const payeeDoc = await getDoc(
        doc(db, `clients/${user.clientId}/payees`, payeeId)
      );
      const payeeData = payeeDoc.data() || {};
      setPayeeDetails(payeeData);

      const checksQuery = query(
        collection(db, `clients/${user.clientId}/checks`),
        where("payee.id", "==", payeeId),
        orderBy("date", "desc"),
        limit(1)
      );
      const checksSnapshot = await getDocs(checksQuery);
      const recentCheck = checksSnapshot.docs[0]?.data();
      if (recentCheck) {
        delete recentCheck.date;
      }

      const payeeName = payeeData.name || "";
      const companyName = payeeData.companyName || "";
      const payee =
        payeeName && companyName
          ? `${payeeName} | ${companyName}`
          : payeeName || companyName;

      const checkData = {
        ...recentCheck,
        checkNumber: "To Be Printed",
        payeeName: payeeData.name,
        payeeCompanyName: payeeData.companyName,
        payeeId: payeeDoc.id,
        payee,
        payableTo: payeeData.payableTo,
        toBePrinted: true,
      };
      setToBePrinted(true);

      if (DEBUG_MODE) {
        console.log("Current payee data:", payeeData);
        console.log("Recent check data:", recentCheck);
        console.log("Current form data:", checkData);
      }

      form.setFieldsValue(checkData);
      updateTextArea(payeeData);
    }
  };

  const updateTextArea = (payeeData) => {
    if (!payeeData) return;

    const { name, companyName, address = {} } = payeeData;

    const payeeInfo = [
      name ? name : null,
      companyName ? companyName : null,
      address.address1 ? address.address1 : null,
      address.address2 ? address.address2 : null,
      [address.city, address.state, address.zip].filter(Boolean).join(", "),
    ]
      .filter(Boolean)
      .join("\n");

    form.setFieldsValue({ mailingInfo: payeeInfo });
  };

  const handleCheckboxChange = (checked) => {
    if (checked) {
      form.setFieldsValue({ checkNumber: "To Be Printed" });
      form.setFieldsValue({ toBePrinted: true });
      setToBePrinted(true);
    } else {
      form.setFieldsValue({
        checkNumber: editingCheck?.checkNumber || "Draft",
      });
      form.setFieldsValue({ toBePrinted: false });
      setToBePrinted(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const formattedValues = {
        ...values,
        date: values.date.toDate(),
        comments: values.comments || "", // Handle comments field properly
        memo: values.memo || "",
        payee: {
          name: values.payeeName,
          id: values.payeeId,
          companyName: values.payeeCompanyName,
        },
      };
      formattedValues.status =
        formattedValues.checkNumber === "To Be Printed"
          ? "to be printed"
          : "draft";

      delete formattedValues.payeeName;
      delete formattedValues.payeeId;

      onSubmit(formattedValues);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleCancel = () => {
    // form.resetFields();
    setIsDropdownVisible(false);
    onCancel();
  };

  const handleAddNewPayee = () => {
    setIsDropdownVisible(false);
    setIsPayeeModalVisible(true);
  };

  const handlePayeeModalOk = async (newPayeeId) => {
    await handlePayeeSelect(newPayeeId);
    setIsPayeeModalVisible(false);
  };

  const handleSaveAndClose = async () => {
    try {
      // Await the validation to ensure it completes before proceeding
      await form.validateFields();
  
      // If validation passes, proceed to submit the form
      const rs = await handleSubmit();
      notification.success({ message: "Saved" });
      onCancel();
    } catch (error) {
      // Handle validation errors or other errors during submission
      notification.warning({
        message: "Please complete all required fields or correct errors",
      });
    }
  };
  const handleSaveAndNew = async () => {
    try {
      // Await the validation to ensure it completes before proceeding
      await form.validateFields();
  
      // If validation passes, proceed to submit the form
      const rs = await handleSubmit();
      notification.success({ message: "Saved" });
      
      // Reset the form and prepare for a new entry
      form.resetFields();
      newDefault();
      setPayeeDetails({});
      setCheckAmount(null);
    } catch (error) {
      // Handle validation errors or other errors during submission
      notification.error({
        message: "Please complete all required fields or correct errors",
      });
    }
  };

  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              name="template"
              label="Select Check Template"
              rules={[
                { required: true, message: "Please select check template" },
              ]}
            >
              <Select>
                {templates.map((template) => (
                  <Option key={template.title} value={template.title}>
                    {template.Title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="date"
              label="Check Date"
              rules={[{ required: true, message: "Please input the date!" }]}
            >
              <DatePicker format="MM-DD-YYYY" />
            </Form.Item>
          </Col>
          <Col span={4}>
            {!TO_BE_PRINTED_STATUSES.includes(editingCheck?.status) ? (
              <Form.Item label="To Print" name="toBePrinted">
                <Checkbox
                  onChange={(e) => {
                    handleCheckboxChange(e.target.checked);
                  }}
                  checked={toBePrinted}
                ></Checkbox>
              </Form.Item>
            ) : null}
          </Col>
          <Col span={4}>
            <Form.Item name="checkNumber" label="Check Number">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              label="Choose Payee"
              rules={[{ required: true, message: "Please select a payee" }]}
            >
              <Select
                showSearch
                placeholder="Begin typing 3 letters to search"
                value={payeeDetails?.name || ""}
                onSearch={handlePayeeSearch}
                onSelect={handlePayeeSelect}
                filterOption={false}
                notFoundContent={null}
                open={isDropdownVisible}
                onDropdownVisibleChange={(open) => setIsDropdownVisible(open)}
              >
                <Option key="add_new" value="add_new">
                  Add New Payee
                </Option>
                {payeeSearchResults.length > 0
                  ? payeeSearchResults.map((payee) => (
                      <Option key={payee.name} value={payee.id}>
                        {payee.name && payee.companyName
                          ? `${payee.name} | ${payee.companyName}`
                          : payee.name || payee.companyName}
                      </Option>
                    ))
                  : null}
              </Select>
              <p
                style={{ fontSize: "10px", marginTop: "-3px", color: "green" }}
              >
                Start typing 3 letters to search
              </p>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="payableTo"
              label="Check Payable To"
              rules={[{ required: true, message: "Enter the name or company" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Please input the amount!" }]}
            >
              <Input
                prefix="$"
                onBlur={(e) => setCheckAmount(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} style={{ display: "none" }}>
          <Col span={6}>
            <Form.Item name="payeeName" label="Payee Name">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="payeeId" label="Payee ID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="payeeCompanyName" label="Payee ID">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={10}>
            <Form.Item name="mailingInfo" label="Mailing Information">
              <TextArea
                rows={4}
                placeholder="Name & Company & Address"
                style={{ fontFamily: "monospace" }}
              />
            </Form.Item>
          </Col>

          <Col span={14}>
            <Form.Item name="memo" label="Check Memo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Title level={5} style={{ marginBottom: -10, marginTop: 0 }}>
          Optional Information
        </Title>
        <Divider style={{ marginBottom: "16px" }} />
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item
              name="comments"
              label="Internal Comments (Not Printed on Check)"
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" onClick={handleSaveAndClose}>
            Save & Close
          </Button>
          <Button
            type="primary"
            onClick={handleSaveAndNew}
            style={{ marginLeft: 8 }}
          >
            Save & New
          </Button>
          <Button onClick={handleCancel} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>

      <PayeeModal
        visible={isPayeeModalVisible}
        onClose={() => setIsPayeeModalVisible(false)}
        isEditMode={false}
        onOk={handlePayeeModalOk}
      />
    </>
  );
};

export default CheckForm;

import React, { useState, useEffect } from "react";
import { Footer } from "antd/es/layout/layout";

function FooterBottom() {
  return (
    <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
      Simple Checks
    </Footer>
  );
}

export default FooterBottom;

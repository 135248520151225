// src/components/Login.js
import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Space,
  notification,
  Card,
  Divider,
} from "antd";
import { EditOutlined, GoogleOutlined, LoginOutlined } from "@ant-design/icons";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase";
import { Link, useNavigate } from "react-router-dom";

const { Title } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const LANDING_PAGE = "/checks";

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // notification.success({ message: "User is already logged in" });
  //       // Redirect or other actions
  //       navigate("/");
  //     }
  //   });
  // }, []);
  const onFinish = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      window.location.href = LANDING_PAGE;
    } catch (error) {
      notification.error({
        message: "Error signing in - password or email incorrect",
      });
    }
    setLoading(false);
  };

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // notification.success({ message: "Login with Google Successful" });
      // Redirect or other actions
      navigate(LANDING_PAGE);
    } catch (error) {
      notification.error({
        message: "Error signing in: " + error.message,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          <img
            src="images/logo/logo.png"
            alt="logo"
            height="200px"
            width="200px"
          />
        </div>
        <Form name="login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "email required" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "password required" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginTop: "-20px" }}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </Form.Item>
          <Form.Item style={{ marginTop: "20px" }}>
            <Button htmlType="submit" type="primary" loading={loading} block>
              <LoginOutlined /> Login
            </Button>
          </Form.Item>
        </Form>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Button
            icon={<GoogleOutlined />}
            onClick={loginWithGoogle}
            loading={loading}
            style={{
              width: "100%",
              // fontWeight: 'bold',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ marginRight: "10px" }}>Login with Google</span>
          </Button>
          <Divider style={{ margin: "10px 0" }} />
          <Link to="/register">
            <Button loading={loading} type="default" block>
              <EditOutlined /> Register
            </Button>
          </Link>
        </Space>
      </Card>
    </div>
  );
};

export default Login;

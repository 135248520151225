import DOMPurify from 'dompurify';

export function getInitialsFullName(fullName){
    const names = fullName.split(' ');
    const initials = names.map((n) => n[0]).join('');
    return initials.toUpperCase();

}

export const sanitizeValue = (value) => {
  if (value === undefined) {
    return null; // Replace undefined with null
  }
  if (typeof value === 'string') {
    return DOMPurify.sanitize(value);
  }
  return value;
};

export const sanitizeValues = (values) => {
    const sanitized = {};
    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const value = values[key];
        sanitized[key] = (value && typeof value === 'object' && !Array.isArray(value))
          ? sanitizeValues(value)  // Recursively sanitize nested objects
          : sanitizeValue(value);  // Sanitize individual values
      }
    }
    return sanitized;
  };
import React from "react";
import { Table, Button, Tooltip, Space, Badge, Popconfirm } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../utils/functions/dates";
import * as XLSX from "xlsx";

const CheckTable = ({ checks, showModal, handleDelete }) => {
  const navigate = useNavigate();

  const exportToExcel = () => {
    const dataToExport = checks.map((check) => {
      return {
        "Check Number": check.checkNumber,
        Amount: `$${parseFloat(check.amount).toFixed(2)}`,
        Status: check.status,
        Payee: check.payee.name,
        "Payable To": check.payableTo,
        Date: formatDate(check.date),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Checks");

    XLSX.writeFile(workbook, "checks.xlsx");
  };

  const columns = [
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Tooltip title="View">
            {/* <Button
              icon={<EyeOutlined />}
              type="dashed"
              shape="circle"
              onClick={() => navigate(`/checks/${record.id}`)}
            ></Button> */}
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              type="dashed"
              shape="circle"
              onClick={() => showModal(record)}
            ></Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure to delete this check?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Tooltip title="Delete" color="darkRed">
              <Button
                icon={<DeleteOutlined />}
                type="dashed"
                shape="circle"
                disabled={record.status === "printed"}
              ></Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Check Number",
      dataIndex: "checkNumber",
      key: "checkNumber",
      sorter: (a, b) => a.checkNumber - b.checkNumber,
      filters: [...new Set(checks.map(check => check.checkNumber))].map(checkNumber => ({
        text: checkNumber,
        value: checkNumber,
      })),
      onFilter: (value, record) => record.checkNumber === value,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Draft", value: "draft" },
        { text: "To Be Printed", value: "to be printed" },
        { text: "Printed", value: "printed" },
        { text: "Void", value: "void" },
      ],
      onFilter: (value, record) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => {
        let color;
        switch (status) {
          case "draft":
            color = "lightgray";
            break;
          case "to be printed":
            color = "darkgray";
            break;
          case "printed":
            color = "green";
            break;
          case "void":
            color = "red";
            break;
          default:
            color = "gray";
            break;
        }
        return (
          <Badge
            color={color}
            text={status.charAt(0).toUpperCase() + status.slice(1)}
          />
        );
      },
    },
    {
      title: "Payee",
      dataIndex: ["payee", "name"],
      key: "payee.name",
      filters: [...new Set(checks.map(check => check.payee.name))].map(payeeName => ({
        text: payeeName,
        value: payeeName,
      })),
      onFilter: (value, record) => record.payee.name === value,
      sorter: (a, b) => a.payee.name.localeCompare(b.payee.name),
    },
    {
      title: "Payable To",
      dataIndex: "payableTo",
      key: "payableTo",
      filters: [...new Set(checks.map(check => check.payableTo))].map(payableTo => ({
        text: payableTo,
        value: payableTo,
      })),
      onFilter: (value, record) => record.payableTo === value,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (date) => formatDate(date),
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={checks}
        pagination={{ pageSize: 10 }}
      />
    </>
  );
};

export default CheckTable;

import { useState, useEffect } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import WordPressArticlesCard from "./WordpressArticles";

function Help() {
  const [articles, setArticles] = useState([]);
  const categoryId = 5; // Replace with your specific category ID

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        // Step 1: Fetch posts from a specific category
        const response = await axios.get(
          "https://simple-checks.com/wp-json/wp/v2/posts",
          {
            params: {
              categories: categoryId,
            },
          }
        );
        const data = response.data;

        // Step 2: Fetch featured images for each post
        const articlesWithImages = await Promise.all(
          data.map(async (article) => {
            let imageUrl = "default_image_url.jpg"; // Fallback if no image is available

            if (article.featured_media) {
              const mediaResponse = await axios.get(
                `https://simple-checks.com/wp-json/wp/v2/media/${article.featured_media}`
              );
              imageUrl = mediaResponse.data.source_url;
            }

            return {
              title: article.title.rendered,
              image: imageUrl,
              summary: article.excerpt.rendered,
              url: article.link,
            };
          })
        );

        setArticles(articlesWithImages);
      } catch (error) {
        console.error("Error fetching articles or images:", error);
      }
    };

    fetchArticles();
  }, [categoryId]);

  return (
    <>
      <h1>
        <QuestionCircleOutlined /> Help Center
      </h1>
      <div style={{ padding: "20px" }}>
        <WordPressArticlesCard articles={articles} />
      </div>
    </>
  );
}

export default Help;

// src/components/Register.js
import React, { useState } from "react";
import { Form, Input, Button, Typography, Card, notification } from "antd";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom"; // Updated import
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

const { Text } = Typography;

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const onFinish = async (values) => {
    const { email, password } = values;

    try {
      // Register the user with Firebase
      await createUserWithEmailAndPassword(auth, email, password);
      notification.success({
        message: "Registration Successful! Please login to continue.",
      });
      notification.info({
        message:
          "Please go to your inbox to confirm your email. Check Spam/Junk folder",
      });
      await sendEmailVerification(auth.currentUser);
      // Redirect to root
      navigate("/");
    } catch (error) {
      console.error("Registration Error:", error.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        title="Register New Account"
        type="inner"
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* <div style={{ textAlign: 'center' }}>
          <Title level={2}>Register</Title>
        </div> */}
        <Text>Please enter your email and password to register.</Text>
        <Form name="register" onFinish={onFinish} style={{ marginTop: "15px" }}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your Email" }]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your Password" },
              {
                min: 8, // Ensure at least 8 characters
                message: "Password must be at least 8 characters",
              },
              {
                pattern:
                  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                message:
                  "Password must include at least one uppercase letter, one number, and one special character",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Register
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Register;

import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";


// Import layouts
import MainMenu from "../../layout/MainMenu";
import TopHeader from "../../layout/TopHeader";
import FooterBottom from "../../layout/FooterBottom";

import { Layout, Typography } from "antd";
import { Link } from "react-router-dom";

const { Title } = Typography;
const { Sider, Content } = Layout;

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { user } = useAuth();

  const [collapsed, setCollapsed] = useState(false);

  const returnTitle = () => {
    return collapsed ? (
      <img src="images/logo/logo.png" alt="logo" height="60px" width="60px" />
    ) : (
      <span style={{fontFamily: 'Montserrat', fontSize:"23px" }}>Simple Checks</span>
    );
  };

  if (!user) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" replace />;
  }

  // Render the component if user is authenticated
  return (
    <Layout>
    
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ height: "100%", backgroundColor: "white" }}
      >
        <div
          style={{
            height: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#2980b9",
          }}
        >
          <Title
            level={3}
            style={{
              margin: "0 0 0 10px",
            }}
          >
            <Link to="/" style={{ color: "white" }}>
              {returnTitle()}
            </Link>
          </Title>
        </div>
        <MainMenu />
      </Sider>
      <Layout>
        <TopHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          style={{
            margin: "0px 16px",
            // padding: 2,
            minHeight: "500px",
            overflow: "auto",
            // background: "white",
            // borderRadius: "white",
          }}
        >
          <Element />
        </Content>
        <FooterBottom />
      </Layout>
    </Layout>
  );
};

export default ProtectedRoute;

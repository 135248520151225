import React from 'react';
import { Form, Input, Radio, Button } from 'antd';

const StepOne = ({ setSelectedCheck, checkOptions, setCheckOptions, setCurrentStep }) => {
  const onCheckTypeChange = (e) => {
    const value = e.target.value;
    let checkSrc = 'check1a.png';
    if (value === 'blank') checkSrc = 'check2a.png';
    if (value === 'custom') checkSrc = 'check3a.png';
    if (value === 'myown') checkSrc = ''; // Handle custom logic for user upload

    setSelectedCheck(checkSrc);
    setCheckOptions({ ...checkOptions, checkType: value });
  };

  const onCheckPositionChange = (e) => {
    const position = e.target.value;
    setCheckOptions({ ...checkOptions, checkPosition: position });

    let checkSrc = `check1${position.charAt(0)}.png`; // e.g., check1b.png for middle
    setSelectedCheck(checkSrc);
  };

  return (
    <Form layout="vertical">
      <Form.Item label="Template Name">
        <Input placeholder="Enter template name" rules={{required: true, message: 'Please enter a template name'}} />
      </Form.Item>
      <Form.Item label="Check Type">
        <Radio.Group onChange={onCheckTypeChange} value={checkOptions.checkType} rules={{required:true, message: 'Please select a check type'}}>
          <Radio value="preprinted">Pre-printed Check</Radio>
          <Radio value="blank">Blank Check</Radio>
          <Radio value="custom">Completely Blank</Radio>
          <Radio value="myown">My Own Check</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Check Position">
        <Radio.Group onChange={onCheckPositionChange} value={checkOptions.checkPosition}>
          <Radio value="a">Check on Top</Radio>
          <Radio value="b">Check in the Middle</Radio>
          <Radio value="c">Check on Bottom</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => setCurrentStep(1)}>Next</Button>
      </Form.Item>
    </Form>
  );
};

export default StepOne;

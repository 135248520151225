import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

export async function isTrialExpired(clientId, trialExpiresOn) {
  if (trialExpiresOn < new Date()) {
    const clientRef = doc(db, "clients", clientId);
    await updateDoc(clientRef, { hasAccess: false });
    return true;
  } else {
    return false;
  }
}

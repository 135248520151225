import React from "react";
import { Modal, Input, Table, Checkbox, notification } from "antd";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import { formatDate } from "../../../utils/functions/dates";
import {
  convertAmountToWords,
  currencyFormatter,
} from "../../../utils/functions/numbers";

const PrintChecksModal = ({
  isVisible,
  onOk,
  onCancel,
  checksToPrint,
  selectedChecksToPrint,
  setSelectedChecksToPrint,
  startingCheckNumber,
  setStartingCheckNumber,
  template,
  isPDFModalVisible,
  handlePrintConfirm,
}) => {
  const columns = [
    {
      title: "Select",
      key: "select",
      render: (_, record) => (
        <Checkbox
          checked={selectedChecksToPrint.includes(record)}
          onChange={() => {
            const selected = [...selectedChecksToPrint];
            if (selected.includes(record)) {
              setSelectedChecksToPrint(
                selected.filter((item) => item.id !== record.id)
              );
            } else {
              selected.push(record);
              setSelectedChecksToPrint(selected);
            }
          }}
        />
      ),
    },
    { title: "Check Number", dataIndex: "checkNumber", key: "checkNumber" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${parseFloat(amount).toFixed(2)}`,
    },
    { title: "Payable To", dataIndex: "payableTo", key: "payableTo" },
    {
      title: "Check Memo",
      dataIndex: "memo",
      key: "memo",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => formatDate(date),
    },
  ];

  const styles = StyleSheet.create({
    page: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    section: {
      position: "relative",
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    textOverlay: {
      position: "absolute",
      fontSize: 12,
      color: "black",
    },
  });

  const renderDynamicText = (text, coordinates) => {
    if (Array.isArray(coordinates)) {
      return coordinates.map((coord, index) => (
        <Text
          key={index}
          style={{
            ...styles.textOverlay,
            left: coord?.x || 0,
            top: coord?.y || 0,
            fontSize: "10px",
          }}
        >
          {text}
        </Text>
      ));
    } else {
      return (
        <Text
          style={{
            ...styles.textOverlay,
            left: coordinates?.x || 0,
            top: coordinates?.y || 0,
            fontSize: "10px",
          }}
        >
          {text}
        </Text>
      );
    }
  };

  return (
    <>
      <Modal
        title="Select Checks to Print"
        open={isVisible}
        onOk={() => {
          if (!startingCheckNumber) {
            notification.error({ message: "Enter starting check number" });
            return;
          } else {
            onOk();
          }
        }}
        onCancel={onCancel}
        style={{
          minWidth: "70%",
          marginTop: -70,
          maxHeight: "60%",
          overflow: "auto",
        }}
        maskClosable={false}
      >
        <Input
          value={startingCheckNumber}
          style={{ width: "300px", marginTop: "20px", marginBottom: "20px" }}
          onChange={(e) => setStartingCheckNumber(e.target.value)}
          placeholder="Enter starting check number"
        />
        <Table
          rowKey="id"
          columns={columns}
          dataSource={checksToPrint}
          pagination={false}
        />
      </Modal>
      <Modal
        title="Print Preview"
        open={isPDFModalVisible}
        onOk={() => handlePrintConfirm(true)}
        onCancel={() => handlePrintConfirm(false)}
        style={{ minWidth: "70%", maxHeight: "70vh" }}
        maskClosable={false}
      >
        <PDFViewer
          style={{ width: "100%", minHeight: "70vh", overflow: "auto" }}
        >
          <Document>
            {selectedChecksToPrint.map((check, index) => {
              const checkTemplate = template.find(
                (template) => template.title === check.template
              );

              return (
                <Page size="LETTER" style={styles.page} key={check.id}>
                  <View style={styles.section}>
                    {checkTemplate?.options?.printCheckNumber &&
                      renderDynamicText(
                        `${parseInt(startingCheckNumber) + parseInt(index)}`,
                        checkTemplate?.coordinates?.checkNumber
                      )}
                    {checkTemplate?.options?.printRoutingAndAccount &&
                      renderDynamicText(
                        `C00${parseInt(startingCheckNumber) + parseInt(index)}C `,
                        checkTemplate?.coordinates?.checkNumber
                      )}
                    {renderDynamicText(
                      checkTemplate?.bankNickname,
                      checkTemplate?.coordinates?.bankNickname
                    )}
                    {renderDynamicText(
                      check.payableTo,
                      checkTemplate?.coordinates?.payableTo
                    )}
                    {renderDynamicText(
                      currencyFormatter(check.amount),
                      checkTemplate?.coordinates?.amount
                    )}
                    {renderDynamicText(
                      convertAmountToWords(check.amount),
                      checkTemplate?.coordinates?.writtenDollar
                    )}
                    {renderDynamicText(
                      formatDate(check.date),
                      checkTemplate?.coordinates?.date
                    )}
                    {renderDynamicText(
                      check.memo,
                      checkTemplate?.coordinates?.memo
                    )}
                    {renderDynamicText(
                      check.mailingInfo,
                      checkTemplate?.coordinates?.mailingInfo
                    )}
                  </View>
                </Page>
              );
            })}
          </Document>
        </PDFViewer>
      </Modal>
    </>
  );
};

export default PrintChecksModal;

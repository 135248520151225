import { Typography, Divider, Alert, Card } from "antd";

const { Title, Paragraph } = Typography;

const TermsOfService = () => (
  <Card>
    <Typography>
      <Title level={2}>Terms of Service</Title>
      <Alert
        message="Important Notice"
        description="This is a binding contract. Please read it carefully before using Simple Checks by Unidas Technology Services LLC."
        type="warning"
        showIcon
      />

      <Divider />

      <Title level={3}>Introduction</Title>
      <Paragraph>
        These Terms of Service ("Terms") form a legally binding agreement
        between you and Unidas Technology Services LLC ("Unidas", "we", "us").
        By accessing or using our services, including creating an account or
        using any feature on our website, you agree to these Terms in their
        entirety. If you do not agree, you may not use our services.
      </Paragraph>

      <Divider />

      <Title level={3}>Account Registration and Security</Title>
      <Paragraph>
        When you register for an account, you agree to provide accurate,
        current, and complete information as prompted by the registration forms.
        You must maintain the confidentiality of your login credentials and are
        responsible for all activities that occur under your account. If you
        suspect any unauthorized use of your account, you must notify us
        immediately at support@simple-checks.com.
      </Paragraph>

      <Divider />

      <Title level={3}>Permitted Use</Title>
      <Paragraph>
        Our services are intended solely for the purpose of printing checks. You
        are strictly prohibited from using our services to create fraudulent,
        fake, or deceptive checks. Check fraud is a serious offense, and we will
        fully cooperate with law enforcement to prosecute any instances of
        fraud. We reserve the right to terminate your account immediately and
        without notice if we suspect any fraudulent activity.
      </Paragraph>

      <Divider />

      <Title level={3}>Changes to Terms</Title>
      <Paragraph>
        Unidas reserves the right to modify these Terms at any time. Any changes
        will be effective immediately upon posting. We will make reasonable
        efforts to notify you of significant changes via the email address
        provided in your registration or by posting a notice on our website.
        Your continued use of the services after any modifications indicates
        your acceptance of the new Terms.
      </Paragraph>

      <Divider />

      <Title level={3}>Privacy Policy</Title>
      <Paragraph>
        Please refer to our Privacy Policy for details on how we collect, use,
        and disclose personal information. By using our services, you consent to
        our collection and use of your information as outlined in the Privacy
        Policy.
      </Paragraph>

      <Divider />

      <Title level={3}>Fees and Payment</Title>
      <Paragraph>
        Subscription fees and other charges for using our services are described
        on our website and may change from time to time. Any changes to pricing
        will be communicated to you with at least 30 days' notice. Your
        continued use of the service after the change indicates your agreement
        with the new fees.
      </Paragraph>

      <Divider />

      <Title level={3}>Content Ownership</Title>
      <Paragraph>
        All content, including data, logos, designs, and software, available
        through our services, is the property of Unidas Technology Services LLC
        or its licensors and is protected by copyright and trademark laws. You
        may not modify, distribute, or reproduce any content from our services
        without prior written permission from us.
      </Paragraph>

      <Divider />

      <Title level={3}>Termination</Title>
      <Paragraph>
        We reserve the right to terminate your access to our services at any
        time, without notice, for any reason, including but not limited to, a
        breach of these Terms or any fraudulent activity detected. Upon
        termination, your account will be deactivated, and your access to the
        services will be revoked.
      </Paragraph>

      <Divider />

      <Title level={3}>Limitation of Liability</Title>
      <Paragraph>
        Unidas Technology Services LLC is not liable for any direct, indirect,
        incidental, or consequential damages resulting from your use of our
        services. Your use of the services is at your own risk.
      </Paragraph>

      <Divider />

      <Title level={3}>Governing Law and Dispute Resolution</Title>
      <Paragraph>
        These Terms are governed by the laws of the State of South Carolina. Any
        disputes arising from these Terms or your use of our services will be
        resolved through binding arbitration in Greenville, South Carolina. If
        arbitration is not permitted by law, disputes will be resolved in the
        courts located in Greenville, South Carolina.
      </Paragraph>

      <Divider />

      <Title level={3}>Contact Us</Title>
      <Paragraph>
        If you have any questions or concerns about these Terms, please contact
        us at contact@simple-checks.com.
      </Paragraph>
    </Typography>
  </Card>
);

export default TermsOfService;

import { useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      await logout(); // Call the logout function
      navigate("/login"); // Redirect to the login page or another appropriate page
    };

    performLogout();
  }, [logout, navigate]);

  return <p>Logging out...</p>;
}
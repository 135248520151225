import React from "react";
import axios from "axios";
import { Button, Modal, notification } from "antd";

const CancelSubscriptionButton = ({
  subscriptionId,
  onSuccess,
  accessToken,
}) => {
  const url = process.env.REACT_APP_API_ENDPOINT;

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.delete(
        `${url}/v1/payments/stripe/subscription/${subscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Assuming you have accessToken available in your component
          },
        }
      );
      if (response.status === 200) {
        notification.success({
          message: "Subscription canceled successfully. You will have access until end of billing cycle",
        });
        if (onSuccess) {
          onSuccess(); // Callback to refresh data or update UI
        }
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      notification.error({
        message: "Failed to cancel subscription. Please try again.",
      });
    }
  };

  const showConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to cancel your subscription?",
      content:
        "You will have access to the system until the end of the current month",
      okText: "Yes, Cancel",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleCancelSubscription();
      },
      onCancel() {
        console.log("Cancel action");
      },
    });
  };

  return (
    <Button
      onClick={showConfirm}
      className="cancel-subscription-button"
      type="dashed"
      danger
    >
      Cancel Subscription
    </Button>
  );
};

export default CancelSubscriptionButton;

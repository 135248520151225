import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../firebase";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  Form,
  Input,
  Button,
  Card,
  Typography,
  Spin,
  Select,
  Checkbox,
} from "antd";
import { useNavigate } from "react-router-dom";
import { sendEmail } from "../../utils/functions/mailer";
import { Link } from "react-router-dom";
import { defaultTemplate } from "../../shared/checkTemplates/default";

const { Title } = Typography;

const Onboarding = () => {
  const DEBUG_MODE = process.env.REACT_APP_ENVIRONMENT === "dev" ? true : false;
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { Option } = Select;

  useEffect(() => {
    if (user && user.clientId) {
      navigate(process.env.REACT_APP_STARTING_PAGE || "/");
    }
  }, [user]);

  const [formData, setFormData] = useState({
    displayName: user?.displayName || "",
    companyName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: user?.email || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const functions = getFunctions();
  const setCustomClaims = async (uid, clientId, role) => {
    const setCustomClaimsFunction = httpsCallable(functions, "setCustomClaims");

    try {
      const result = await setCustomClaimsFunction({ uid, clientId, role });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (error) {
      console.error("Error setting custom claims:", error);
    }
  };

  const generateSecret = async (clientId) => {
    const generateSecretFunction = httpsCallable(functions, "generateSecret");
    try {
      const result = await generateSecretFunction({ clientId });
      if (DEBUG_MODE) console.log(result.data.message);
    } catch (e) {
      console.error("Error setting custom claims:", e.message);
    }
  };

  const handleSubmit = async (values) => {
    if (!values.displayName.includes(" ")) {
      alert("Please enter your full name.");
      return;
    }
    setLoading(true);

    try {
      const userRef = doc(db, "users", user.uid);

      await setDoc(userRef, {
        ...values,
        isAdmin: true,
        role: "admin",
        isDeleted: false,
        clientId: "",
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      const clientRef = await addDoc(collection(db, "clients"), {
        clientName: values.companyName || values.displayName,
        hasAccess: true,
        subscription: "trial",
        trialExpiresOn: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        users: [
          {
            userId: user.uid,
            name: values.displayName,
            email: values.email,
            role: "admin",
          },
        ],
        dateAdded: serverTimestamp(),
        dateModified: serverTimestamp(),
      });

      await addDoc(collection(db, `clients/${clientRef.id}/templates`), {
        ...defaultTemplate,
      });

      await updateDoc(userRef, {
        clientId: clientRef.id,
      });

      await setCustomClaims(user.uid, clientRef.id, "admin");

      await generateSecret(clientRef.id);

      try {
        await sendEmail(
          user,
          process.env.REACT_APP_ADMIN_EMAIL,
          "New Client",
          JSON.stringify(user)
        );
      } catch (e) {
        console.error(e.message);
      }

      setLoading(false);
      window.location.reload(true);
      window.location.href = process.env.REACT_APP_STARTING_PAGE || "/";
    } catch (error) {
      setLoading(false);
      console.error("Error updating user data:", error.message);
    }
  };

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        style={{
          width: 400,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={2}>Onboarding</Title>
        </div>
        <Form
          name="user_form"
          initialValues={formData}
          onFinish={handleSubmit}
          onValuesChange={(changedValues) =>
            handleChange({
              target: {
                name: Object.keys(changedValues)[0],
                value: Object.values(changedValues)[0],
              },
            })
          }
        >
          <Form.Item name="email">
            <Input placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            name="displayName"
            rules={[{ required: true, message: "Full name is required" }]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item name="companyName">
            <Input placeholder="Company Name (Optional)" />
          </Form.Item>
          <Form.Item
            name="address"
            rules={[{ required: true, message: "Please enter your address" }]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input placeholder="City" />
          </Form.Item>
          <Form.Item
            name="state"
            rules={[{ required: true, message: "Please enter your state" }]}
          >
            <Select placeholder="Select a state">
              {states.map((state) => (
                <Option key={state} value={state}>
                  {state}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="zip"
            rules={[{ required: true, message: "Please enter your ZIP Code" }]}
          >
            <Input placeholder="ZIP Code" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please enter your phone number" },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>

          <Form.Item
            name="acceptTermsConditions"
            valuePropName="checked" // Ensure proper binding to checked state
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          "You must agree to terms and conditions to proceed"
                        )
                      ),
              },
            ]}
          >
            <Checkbox>
              I certify that I am authorized to print checks for this entity and I agree with{" "}
              <Link
                to="/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </Link>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Onboarding;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Typography,
  Divider,
  Row,
  Col,
  Select,
  notification,
} from "antd";
import { addDoc, updateDoc, doc, collection } from "firebase/firestore";
import { db } from "../../../firebase";
import { sanitizeValues } from "../../../utils/functions/strings";
import { useAuth } from "../../../contexts/AuthContext";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";

const PayeeModal = ({
  visible,
  onClose,
  isEditMode,
  payee,
  onOk,
  fetchPayees,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [nameOnCheck, setNameOnCheck] = useState(null);
  const [nameOnCheckDisabled, setNameOnCheckDisabled] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!isEditMode) {
      setNameOnCheck(null);
      setNameOnCheckDisabled(true);
      form.resetFields();
    }
    if (payee?.payableTo) setNameOnCheckDisabled(false);
    if (payee) {
      form.setFieldsValue({
        ...payee,
        address1: payee?.address?.address1 ?? null,
        address2: payee?.address?.address2 ?? null,
        city: payee?.address?.city ?? null,
        state: payee?.address?.state ?? null,
        zip: payee?.address?.zip ?? null,
        country: payee?.address?.country ?? null,
      });
    }
  }, [payee, form, isEditMode, visible]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (!values.name && !values.companyName) {
        notification.error({
          message: "You must enter either a name or a company name",
        });
        return;
      }
      const now = new Date();
      const transformedValues = {
        ...values,
        address: {
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          zip: values.zip,
          country: values.country,
        },
      };

      delete transformedValues.address1;
      delete transformedValues.address2;
      delete transformedValues.city;
      delete transformedValues.state;
      delete transformedValues.zip;
      delete transformedValues.country;

      const sanitizedValues = sanitizeValues(transformedValues);
      let newPayeeId;

      if (isEditMode && payee) {
        await updateDoc(doc(db, `clients/${user.clientId}/payees`, payee.id), {
          ...sanitizedValues,
          dateModified: now,
          lastEditedBy: { displayName: user.displayName, uid: user.uid },
        });
        newPayeeId = payee.id;
      } else {
        const docRef = await addDoc(
          collection(db, `clients/${user.clientId}/payees`),
          {
            ...sanitizedValues,
            dateAdded: now,
            dateModified: now,
            isDeleted: false,
            clientId: user.clientId,
            lastEditedBy: { displayName: user.displayName, uid: user.uid },
          }
        );
        newPayeeId = docRef.id;
      }
      notification.success({ message: "Saved" });
      form.resetFields();
      if (fetchPayees) {
        fetchPayees();
      }

      if (onOk) {
        onOk(newPayeeId);
      }
      onClose();
    } catch (e) {
      console.error(e.message);
      notification.error({
        message: "Error performing this action",
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const populateNameToPrint = (value) => {
    if (!nameOnCheck) {
      setNameOnCheck(value);
      setNameOnCheckDisabled(false);
      form.setFieldsValue({ payableTo: value });
    }
  };

  return (
    <Modal
      title={isEditMode ? "Edit Payee" : "New Payee"}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ minWidth: "70%", marginTop: -70 }}
      maskClosable={false}
      open={visible}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          status: isEditMode ? payee?.status : "Active",
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={10}>
            <Form.Item name="companyName" label="Company Name">
              <Input onBlur={(e) => populateNameToPrint(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={10}>
            <Form.Item
              name="payableTo"
              label="Name To Print On Check"
              rules={[
                {
                  required: true,
                  message: "Please add name or company to enable",
                },
              ]}
            >
              <Input
                disabled={nameOnCheckDisabled}
                onBlur={(e) => setNameOnCheck(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={4}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              <Select>
                <Select.Option value="Active" key="active">
                  Active
                </Select.Option>
                <Select.Option value="Inactive" key="inactive">
                  Inactive
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5} style={{ marginTop: 0 }}>
          Contact Person
        </Typography.Title>
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="name" label="Name">
              <Input onBlur={(e) => populateNameToPrint(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="phoneNumber" label="Phone Number">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5} style={{ marginTop: 0 }}>
          Address
        </Typography.Title>
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <Row gutter={16}>
          <Col xs={24} sm={12} md={18}>
            <Form.Item name="address1" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="address2" label="Address 2">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="zip" label="Postal Code">
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item name="country" label="Country">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5} style={{ marginTop: 0 }}>
          Optional Information
        </Typography.Title>
        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
        <Form.Item
          name="additionalInfo"
          label="Additional Information / Comments"
        >
          <TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PayeeModal;

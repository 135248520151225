import axios from "axios";

export function sendEmail(user, email, subject, message) {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  try {
    return axios.post(
      `${apiUrl}/v1/mailer`,
      {
        email: email,
        subject: subject,
        message: message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.accessToken,
        },
      }
    );
  } catch (e) {
    console.error(e.message)
    return e.message
  }
}

import React from 'react';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const NotFound = () => {
  const navigate = useNavigate();
  
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    textAlign: 'center'
  };

  const titleStyle = {
    fontSize: '10rem',
    color: '#1890ff'
  };

  const subtitleStyle = {
    color: '#1890ff'
  };

  return (
    <div style={containerStyle}>
      <Title level={1} style={titleStyle}>404</Title>
      <Title level={3} style={subtitleStyle}>Page Not Found</Title>
      <p>The page you are looking for does not exist.</p>
      <Button type="primary" onClick={() => navigate(-1)}>Go Back</Button>
    </div>
  );
};

export default NotFound;
import { Button, Typography, Avatar, Layout } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { getInitialsFullName } from "../utils/functions/strings";
import { useAuth } from "../contexts/AuthContext";
const { Header } = Layout;

const { Text } = Typography;

function TopHeader({ collapsed, setCollapsed }) {
  const { user } = useAuth();
  return (
    <Header
      style={{
        padding: 0,
        backgroundColor: "#2980b9",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "16px",
        }}
      >
        <div
          style={{
            alignItems: "left",
            marginRight: "16px",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
              color: "white",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            marginRight: "16px",
          }}
        >
          <Text
            style={{ color: "white", marginRight: "12px", fontSize: "16px" }}
          >
            {user && user.displayName}
          </Text>
          {user && user.photoURL ? (
            <Avatar src={user.photoURL } size={40} />
          ) : (
            <Avatar
              draggable={true}
              size={40}            >
              {getInitialsFullName(user?.displayName || "not available")}
            </Avatar>
          )}
        </div>
      </div>
    </Header>
  );
}

export default TopHeader;

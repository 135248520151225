import React, { useState } from "react";
import { Upload, Button } from "antd";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const UploadAndCrop = () => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleUpload = async (file) => {
    // Handle the upload to Firebase and update the image URL
  };

  const onCrop = () => {
    if (image) {
      setCroppedImage(image.cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <div>
      <Upload customRequest={handleUpload}>
        <Button>Select Image</Button>
      </Upload>
      {image && (
        <Cropper
          src={image}
          style={{ height: 400, width: "100%" }}
          // Cropper.js options
          aspectRatio={16 / 9}
          guides={false}
          crop={onCrop}
        />
      )}
    </div>
  );
};

export default UploadAndCrop;
